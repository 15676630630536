// import { loadRemoteEntry } from '@angular-architects/module-federation';
// import { environment } from './environments/environment';

// Promise.all([
//   loadRemoteEntry({
//     type: 'module',
//     remoteEntry: environment.themesEntry,
//   }),
// ])
//   .catch((err) => console.error('Error loading remote entries', err))
//   .then(() => import('./bootstrap'))
//   .catch((err) => console.error(err));

import('./bootstrap')
  .catch(err => console.error(err));
